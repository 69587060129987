import '@yaireo/tagify/dist/tagify.css';
import './css/main.css';

import Tagify from '@yaireo/tagify'

import ScrollMagic from 'scrollmagic';
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

let controller = new ScrollMagic.Controller();

function progressBar() {
    let progressContainer = document.getElementsByClassName("progressCont");
    if (progressContainer.length > 0) {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("progressBar").style.width = scrolled + "%";
    };
};

window.onscroll = function() {progressBar()};

let contentsUpdater = document.getElementsByClassName("contentsUpdater");
if (contentsUpdater.length > 0) {
    let i;
    let h2 = document.querySelectorAll(".mainContent h2");
    let contentsHeader = document.getElementById("contentHeader");
    let contentsDiv = document.getElementById("projectContents");
    for (i=0; i < h2.length; i ++) {
        let encoded = encodeURI(h2[i].innerHTML);
        let newContentsDiv = document.createElement("li");
        let newContentsLink = document.createElement("a");
        newContentsLink.setAttribute("href", "#" + encoded);
        let newContentsText = document.createTextNode(h2[i].innerHTML);
        newContentsLink.appendChild(newContentsText);
        newContentsDiv.appendChild(newContentsLink);
        contentsDiv.append(newContentsDiv);
        console.log(h2[i].innerHTML);
        let currentHeader = h2[i].innerHTML;
        h2[i].setAttribute("id", encoded);
        new ScrollMagic.Scene({
            triggerElement: h2[i],// y value not modified, so we can use element as trigger as well
            duration: 10
                    })

                    .on("enter", function (i) {contentsHeader.innerHTML = currentHeader})
                    
                    // .addIndicators({name: h2 })
                    
                    .addTo(controller)
    };

    let contentHead = document.getElementById("contentHead");
    contentHead.addEventListener("click", function() {
        document.getElementById("projectContentsOuter").classList.toggle("-translate-y-full");
    });

    let topContent = document.getElementById("topContent");

    new ScrollMagic.Scene({
        triggerElement: topContent,// y value not modified, so we can use element as trigger as well
        duration: "100%"
                })
                .on("enter", function (i) {contentsHeader.innerHTML = "Contents"})
                .addTo(controller)
};

let chapters = document.getElementsByClassName("subtitleTrigger");
if (chapters.length > 0) {
    let contentsHeader = document.getElementById("contentHeader");
    let y;
    for (y=0; y < chapters.length; y++) {
        let chapterTitle = chapters[y].getAttribute("data-chapterTitle");
        new ScrollMagic.Scene({
            triggerElement: chapters[y],// y value not modified, so we can use element as trigger as well
            duration: "10"
                    })
                    .on("enter", function (i) {contentsHeader.innerHTML = chapterTitle})
                    // .addIndicators({name: chapterTitle })
                    .addTo(controller)
    };
};

let projectHeaderBar = document.getElementById("projectHeaderBar");
if (typeof(projectHeaderBar) != 'undefined' && projectHeaderBar != null ) {
    let projectHeaderTag = document.getElementById("projectHeaderTag");
    new ScrollMagic.Scene({
        duration: "100%",
        triggerHook: 0,
        offset: "-50px"
                })
                .on("enter", function (i) { 
                    projectHeaderBar.classList.add("-translate-y-full");
                    projectHeaderTag.classList.remove("-translate-y-32");
                })
                .on("leave", function (i) { 
                    projectHeaderBar.classList.remove("-translate-y-full"); 
                    projectHeaderTag.classList.add("-translate-y-32");
                })
                // .addIndicators({name: "TOP" })
                .addTo(controller)
};

let ctButtonsClass = document.getElementsByClassName("contentsButtons");
if (ctButtonsClass.length > 0) {
    let ctButton = document.getElementById("contentsButton");
    let ctHeader = document.getElementById("contentsButtonHeader");
    let ctContent = document.getElementById("contentsContent");
    ctButton.addEventListener("mouseenter", ctMouseEnter);

    ctButton.addEventListener("mouseleave", ctMouseLeave);

    function ctMouseEnter() {
        let status = ctButton.getAttribute("data-status");
        // ctButton.setAttribute("data-status", "o");

        ctHeader.classList.add("opacity-0");
        setTimeout(function(){ctHeader.classList.add("hidden")}, 200);
        setTimeout(function(){ctContent.classList.remove("hidden")}, 700);
        setTimeout(function(){ctButton.classList.add("contentsOpen")}, 900);
        setTimeout(function(){ctContent.classList.remove("opacity-0")}, 1500);
    };

    function ctMouseLeave() {

        // ctButton.removeEventListener("mouseover", ctMouseEnter);

        let status = ctButton.getAttribute("data-status");
        // ctButton.setAttribute("data-status", "o");       
        ctContent.classList.add("opacity-0");
        setTimeout(function(){ctButton.classList.remove("contentsOpen")}, 200);
        setTimeout(function(){ctContent.classList.add("hidden")}, 500);
        setTimeout(function(){ctHeader.classList.remove("hidden")}, 700);
        
        
        setTimeout(function(){ctHeader.classList.remove("opacity-0")}, 900);
        // setTimeout(function(){ctButton.addEventListener("mouseover", ctMouseEnter);}, 2000);
    }
};

let scrollTo = document.getElementsByClassName("scrollTo");
if (scrollTo.length > 0) {
    let t;
    for (t=0; t < scrollTo.length ; t++) {
        let scrollToData = scrollTo[t].getAttribute("data-scrollTo");
        let target = document.getElementById(scrollToData);
        scrollTo[t].addEventListener("click", function() {target.scrollIntoView()});
    };
};

// let catButtons = document.getElementsByClassName("catButtons");
// const urlSearchParams = window.location.search;
// let urlParams = new URLSearchParams(urlSearchParams);

// if (catButtons.length > 0) {
    
//     let i;
//     for (i = 0; i < catButtons.length; i++) {
        
//         let currentButt = catButtons[i];
//         currentButt.addEventListener("click", function() {
//             let cat = currentButt.getAttribute("data-cat");

//             if (window.location.search.includes(cat) === true ) {
                
//                 let currentCats = urlParams.getAll('cat');
//                 console.log("currentCats" + currentCats);

//                 let removedCat = currentCats.replace(cat, '');
//                 urlParams.set("cat", cat);
//                 window.location.search = removedCat;

//             } else {
            
//                 if (urlParams.has('cat') == true) {
//                     let urlCats = urlParams.getAll('cat').join("---");
//                     urlParams.set("cat", urlCats + "---" + cat);
//                     window.location.search = urlParams;
//                 } else {
//                     urlParams.set("cat", cat);
//                     window.location.search = urlParams;
//                 };

//             };

//         });
//     };
// };

let searchTag = document.getElementsByClassName("searchTag");
if (searchTag.length > 0) {
    let form = document.getElementById("discoverForm");
    let input = document.getElementById("discoverInput");
    let submitButton = document.getElementById("submitButton");

    let tagify = new Tagify(input, {
        // mode: 'mix',
    });

    

    let i;
    for (i = 0; i < searchTag.length; i++) {
        let tagTerm = searchTag[i].getAttribute("data-tag");
        searchTag[i].addEventListener("click", function() {
        tagify.addTags(tagTerm);
        });
    };

    submitButton.addEventListener("click", function() {
        let stringValues = tagify.value.map(x => x.value).join("   ");
        input.value = stringValues;
        form.submit();
    });

    input.addEventListener('change', onChange)



    function onChange(e){
        // outputs a String
        let names = tagify.value.map(x => x.value).join("   ");
        console.log(names)
      }

} else false;